import commonApiMixin from '@/mixins/modules/__commonApiMixin'
import avatarsMixin from "@/mixins/modules/avatarsMixin";
import teamsMixin from "@/mixins/modules/teamsMixin";
import storageMixin from "@/mixins/modules/storageMixin";
import {Query} from "appwrite";

export default {
    mixins: [commonApiMixin, avatarsMixin, teamsMixin, storageMixin],
    computed: {
        updateBreeders: {
            get() {
                return this.$store.getters.getBreedersUpdated
            },
            set(what) {
                this.$store.commit('shouldUpdateBreeders', what)
            }
        },
        updateOwners: {
            get() {
                return this.$store.getters.getOwnersUpdated
            },
            set(what) {
                this.$store.commit('shouldUpdateOwners', what)
            }
        },
        updateHandlers: {
            get() {
                return this.$store.getters.getHandlersUpdate
            },
            set(what) {
                this.$store.commit('shouldUpdateHandlers', what)
            }
        },
        retrials() {
            return process.env.VUE_APP_RETRY_FUNCTIONS_REQUESTS ?
                process.env.VUE_APP_RETRY_FUNCTIONS_REQUESTS : 10
        }
    },
    methods: {
        async getBreeds() {
            return await this.__get(
                'breeds',
                [
                    Query.orderAsc('name')
                ]
            )
        },
        async deleteBreed(breedId) {
            return await this.__delete('breeds', breedId)
        },
        async createBreed(data) {
            return await this.__create('breeds', data)
        },

        async getPersons(what) {
            return await this.__get(
                'breeders',
                [
                    Query.equal(what, true),
                    Query.orderAsc('name')
                ]
            )
        },
        async getSinglePerson(id) {
            return await this.__getSingle('breeders', id)
        },
        async savePerson(data, updateMode, id = null) {
            if (updateMode && id) {
                return await this.__update('breeders', id, data)
            } else if (!updateMode) {
                return await this.__create('breeders', data)
            } else {
                console.error('ID is required for update')
                return false
            }
        },
        async createPerson(data) {
            try {
                const response = await this.databasesSdk.createDocument('breeders',
                    'unique()',
                    data)
                console.log(response)
                return true
            } catch (e) {
                console.error(e)
                return false
            }
        },
        /**
         * @fixme Delete it!
         * @param functionName
         * @param data
         * @returns {Promise<unknown>}
         */
        runFunction(functionName, data) {
            return new Promise((resolve, reject) => {
                try {
                    this.functionsSdk.createExecution(functionName, JSON.stringify(data)).then(executionResponse => {
                        if (executionResponse.status === 'failed') {
                            console.error(executionResponse.stderr)
                            reject(executionResponse.stderr)
                        } else if (executionResponse.status === 'completed') {
                            resolve(JSON.parse(executionResponse.response))
                        } else {
                            let retrials = 0;
                            const retryInterval = setInterval(() => {
                                retrials++
                                if (retrials > this.retrials) {
                                    clearInterval(retryInterval)
                                    reject('Max retrials done')
                                }
                                this.functionsSdk.getExecution(functionName, executionResponse.$id).then(result => {
                                    if (result.status === 'failed') {
                                        console.error(executionResponse.stderr)
                                        reject(executionResponse.stderr)

                                        clearInterval(retryInterval)
                                    } else if (result.status === 'completed') {
                                        resolve(JSON.parse(result.response))

                                        clearInterval(retryInterval)
                                    } else {
                                        if (process.env.NODE_ENV !== 'production') {
                                            console.log('Still running', result.status)
                                        }
                                    }
                                })
                            }, 200)
                        }
                    })
                } catch (e) {
                    console.error(e)
                    reject(e)
                }
            })
        },
        async getUserIdByEmail(email) {
            const data = {
                email: email,
                full: true
            }

            const response = await this.__runFunction('getUserIdByEmail', data)
            if (response && response.result) {
                return response.user
            }
        },
        async deletePerson(id) {
            return await this.__delete('breeders', id)
        },
        async getMetricsList() {
            return await this.__get(
                'metrics_list',
                [
                    Query.orderAsc('name')
                ]
            )
        },
        async deleteMetricsList(id) {
            return this.__delete('metrics_list', id)
        },
        async saveMetricsList(data, updateMode, id = null) {
            if (updateMode && id) {
                return await this.__update('metrics_list', id, data)
            } else if (!updateMode) {
                return await this.__create('metrics_list', data)
            } else {
                console.error('ID is required to make update')
                return false
            }
        },
        async createMetricsList(data) {
            try {
                return await this.databasesSdk.createDocument('metrics_list', 'unique()', data)
            } catch (e) {
                console.error(e)
                return false
            }
        },
        async getSingleMetricsList(id) {
            return await this.__getSingle('metrics_list', id)
        },
        async updateMetricsList(id, data) {
            return await this.__update('metrics_list', id, data)
        },

        async getShows() {
            return await this.__get('shows', [], ['date'], ['DESC'])
        },
        async createShowTemplate(data) {
            return await this.__create('show_types', data)
        },
        async getShowTemplates() {
            return await this.__get('show_types', [], ['name'], ['ASC'])
        },
        async deleteShowTemplate(id) {
            return await this.__delete('show_types', id)
        },
        async getSingleShowTemplate(id) {
            return await this.__getSingle('show_types', id)
        },
        async updateShowTemplate(id, data) {
            return await this.__update('show_types', id, data)
        },
        async getInviteByKey(key) {
            const $queries = [
                Query.equal('key', key),
                Query.equal('is_valid', true),
                Query.equal('is_accepted', false)
            ]

            return await this.__getSingleFromQuery('630782d175913162542a', $queries)
        },
        async getAllInvites() {
            return await this.__get('630782d175913162542a', [
                Query.orderAsc('invite_for')
            ])
        },
        async deleteInvite(id) {
            return await this.__delete('630782d175913162542a', id)
        },

        async getAllDogs() {
            return await this.__get('630b8be9ad8d8841d978', [], ['name'], ['ASC'])
        },

        async acceptInvite(id, password, phone) {
            return await this.__update('630782d175913162542a', id, {
                is_accepted: true,
                password: password,
                phone: phone
            })
        },

        async getEventTypes() {
            return await this.__get(
                'event_types',
                [],
                ['name'],
                ['ASC']
            )
        },

        async saveEventTemplate(data, updateMode, id = null) {
            if (updateMode && id) {
                return await this.__update(
                    'event_types',
                    id,
                    data
                )
            } else if (!updateMode) {
                return await this.__create(
                    'event_types',
                    data
                )
            } else {
                console.error('ID is required for update')
                return null
            }
        },
        async deleteEventTemplate(id) {
            return await this.__delete('event_types', id)
        },
        async getSingleEventTemplate(id) {
            return await this.__getSingle('event_types', id)
        },

        async getAllEvents() {
            return await this.__get('events', [
                Query.orderAsc('name')
            ])
        },
        async createOrUpdateEvent(data, updateMode, id = null) {
            if (updateMode && id) {
                return await this.__update(
                    'events',
                    id,
                    data
                )
            } else if (!updateMode) {
                return await this.__create('events', data)
            } else {
                console.error('ID is required for update')
                return null
            }
        },
        async deleteEvent(id) {
            return await this.__delete('events', id)
        },
        async getSingleEvent(id) {
            return await this.__getSingle('events', id)
        },

        async getDogGroups() {
            const dogGroups = await this.__get(
                'dog_groups',
                [],
                ['name'],
                ['ASC']
            )

            await Promise.all(
                dogGroups.documents.map(async (group) => {
                    console.log('GROUP', group)
                    if (!group.avatar) {
                        group.avatar = await this.getInitials(group.name)
                    }
                })
            )
            return dogGroups
        },
        async getSingleDogGroup(id) {
            return await this.__getSingle(
                'dog_groups',
                id
            )
        },
        async saveDogGroup(data, updateMode, id = null) {
            // console.warn(data, updateMode, id)

            if (!updateMode) {
                return await this.__create(
                    'dog_groups',
                    data
                )
            } else {
                return await this.__update(
                    'dog_groups',
                    id,
                    data
                )
            }
        },
        async deleteDogGroup(id) {
            return await this.__delete('dog_groups', id)
        },

        async saveAchievement(data, updateMode, id = null) {
            if (updateMode && null !== id) {
                return this.__update('achievements', id, data)
            } else if (!updateMode) {
                return this.__create('achievements', data)
            } else {
                console.error('Updated mode is on but no ID specified')
                return false;
            }
        },
        async getAllAchievements() {
            return await this.__get('achievements', [
                Query.orderAsc('name')
            ])
        },
        async getAchievementById(id) {
            return await this.__getSingle('achievements', id)
        },
        async deleteAchievementById(id) {
            return await this.__delete('achievements', id)
        },

        // DOGS
        async getDogs(queries) {
            // FIXME: implement queries

            return await this.__get('630b8be9ad8d8841d978', [
                Query.orderAsc('name')
            ])
        },
        async getAdminDogs(queries) {
            return await this.__runFunction('get_dogs_list', [])
        },
        async getSingleDog(id) {
            return await this.__getSingle('630b8be9ad8d8841d978', id)
        },
        async saveDog(data, updateMode, id = null) {
            if (updateMode && id) {
                console.log('DDDDDDDDDDDDDDDD', data)
                return await this.__update('630b8be9ad8d8841d978', id, data)
            } else if (!updateMode) {
                return await this.__create('630b8be9ad8d8841d978', data)
            } else {
                console.error('ID is necessary in case of update')
                return false
            }
        },
        async deleteDog(id) {
            return await this.__delete('630b8be9ad8d8841d978', id)
        },

        // COLORS
        async getAllColors() {
            return await this.__get(
                'dog_colors',
                [
                    Query.orderAsc('name')
                ]
            )
        },
        async deleteColor(id) {
            return await this.__delete('dog_colors', id)
        },
        async addUpdateColor(data, updateMode, id) {
            if (updateMode && id) {
                // UPDATE
                return await this.__update('dog_colors', id, data)
            } else if (!updateMode) {
                // CREATE
                return await this.__create('dog_colors', data)
            } else {
                // ERROR
                console.error('ID IS NEEDED IF YOU UPDATE')
            }

            return false
        },
        async getSingleColor(id) {
            return await this.__getSingle('dog_colors', id)
        },
    }
}
