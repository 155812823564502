export default {
    computed: {
        showConfirm: {
            get() {
                return this.$store.getters.getComfirm
            },
            set(confirmValues) {
                this.$store.commit('runConfirm', confirmValues)
            }
        },
        updatedTeams: {
            get() {
                return this.$store.getters.getTeamsUpdated
            },
            set(updated) {
                this.$store.commit('updatedTeams', updated)
            }
        },
        isLoading: {
            get() {
                return this.$store.getters.pageIsLoading
            },
            set(loading) {
                this.$store.commit('setLoadPage', loading)
            }
        },
        currentMessage() {
            return this.$store.getters.getMessage
        },
        pageHeader: {
            get() {
                return this.$store.getters.getHeader
            },
            set(header) {
                return this.$store.commit('setHeader', header)
            }
        },
        isInvitesUpdate: {
            get() {
                return this.$store.getters.getUpdateInvites
            },
            set(what) {
                return this.$store.commit('shouldUpdateInvites', what)
            }
        }
    },
    mounted() {
        const waitForStore = setInterval(() => {
            if (this.$store) {
                clearInterval(waitForStore)
            }
        }, 200)
    },
    methods: {
        openMessage(message, type = "info", timeout = -1) {
            this.$store.commit('openMessage', {
                message: message,
                type: type,
                timeout: timeout
            })
        },
        openSuccessMessage(message = '_success') {
            this.openMessage(message, 'success', 3000)
        },
        openFailureMessage(message = '_error') {
            this.openMessage(message, 'error', 3000)
        },
        closeMessage() {
            this.$store.commit('closeMessage')
        },
        runDefaultConfirm(handler) {
            this.runConfirm(
                '_are_you_sure',
                '_cant_be_undone',
                handler
            )
        },
        runConfirm(title, text, handler) {
            this.showConfirm = {
                show: true,
                text: text,
                title: title,
                isRunning: false,
                handler: handler
            }
        },
        cancelConfirm() {
            this.showConfirm = {
                show: false,
                text: '',
                title: '',
                isRunning: false,
                handler: null
            }
        },
        setRunning(what) {
            let confirmData = this.showConfirm
            confirmData.isRunning = what
            this.showConfirm = confirmData
        },
    }

}
