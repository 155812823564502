// USERS store module

const state = () => ({
    isLogged: false,
    account: null,
    session: null,
    isGlobalAdmin: false,
    canCreateGroups: false,
    groups: [],
    notifications: {
        total: 0,
        documents: []
    },
    teams: {},
    avatar: null,
    locale: 'ru',
    imageAvatar: null
})

const getters = {
    getIsLogged: (state) => {
        return state.isLogged
    },
    getAccount: (state) => {
        return state.account
    },
    getNotifications: (state) => {
        return state.notifications
    },
    getIsAdmin: (state) => {
        return state.teams.admin
    },
    getTeams: (state) => {
        return state.teams.teams
    },
    getAvatar: (state) => {
        return state.avatar
    },
    getLocale: (state) => {
        return state.locale
    },
    getSession: (state) => {
        return state.session
    },
    getImageAvatar: (state) => {
        return state.imageAvatar
    }
}

const mutations = {
    setIsLogged: (state, value) => {
        state.isLogged = value
    },
    setAccount: (state, account) => {
        state.account = account
    },
    clearAccount: (state) => {
        state.account = null
    },
    clearSession: (state) => {
        state.session = null
    },
    setNotifications: (state, notifications) => {
        state.notifications = notifications
    },
    setTeams: (state, teams) => {
        state.teams = teams
    },
    setGroups: (state, groups) => {
        state.groups = groups
    },
    setAvatar: (state, avatar) => {
        state.avatar = avatar
    },
    setLocale: (state, locale) => {
        state.locale = locale
    },
    setSession: (state, session) => {
        state.session = session
    },
    setImageAvatar: (state, imageAvatar) => {
        state.imageAvatar = imageAvatar
    }
}

const actions = {
    async cleanLogin({commit}) {
        console.log('CLEAN LOGIN')
        commit('setIsLogged', false)
        commit('clearAccount')
        commit('clearSession')
        commit('setNotifications', {
            total: 0,
            documents: []
        })
        commit('setTeams', {})
        window.localStorage.clear()
    },
    syncLoginData({commit}, payload) {
        return new Promise((resolve) => {
            commit('setAccount', payload.account)
            commit('setIsLogged', true)
            commit('setTeams', payload.teams)
            commit('setGroups', payload.groups)
            commit('setNotifications', payload.notifications)
            commit('setAvatar', payload.avatar)
            commit('setLocale', payload.locale)
            commit('setImageAvatar', payload.imageAvatar)
            resolve()
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
