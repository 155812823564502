import Vue from 'vue'
import Vuex from 'vuex'
import {createLogger} from 'vuex'
import VuexPersistence from "vuex-persist";

// Modules
import userStore from './modules/user'
import interfaceStore from './modules/interface'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})
const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [
    createLogger(),
    vuexLocal.plugin
] : [
    vuexLocal.plugin
]

export default new Vuex.Store({
    modules: {
        userStore,
        interfaceStore
    },
    plugins: plugins
})
