import {Account, Databases, Query, Teams} from 'appwrite'
import apiMixin from "@/mixins/apiMixin";
import interfaceMixin from "@/mixins/interfaceMixin";


export default {
    mixins: [apiMixin, interfaceMixin],
    computed: {
        loggedIn: {
            get() {
                return this.$store.getters.getIsLogged
            },
            set(value) {
                this.$store.commit('setIsLogged', value)
                if (!value) {
                    this.$store.commit('clearAccount')
                }
            }
        },
        account: {
            get() {
                return this.$store.getters.getAccount
            },
            set(account) {
                this.$store.commit('setAccount', account)
            }
        },
        session: {
            get() {
                return this.$store.getters.getSession
            },
            set(session) {
                this.$store.commit('setSession', session)
            }
        },
        notifications: {
            get() {
                return this.$store.getters.getNotifications
            },
            set(notifications) {
                this.$store.commit('setNotifications', notifications)
            }
        },
        teams: {
            get() {
                return this.$store.getters.getTeams
            },
            set(teams) {
                this.$store.commit('setTeams', teams)
                this.updatedTeams = true
            }
        },
        isAdmin() {
            return this.$store.getters.getIsAdmin
        },
        avatar: {
            get() {
                return this.$store.getters.getAvatar
            },
            set(avatar) {
                this.$store.commit('setAvatar', avatar)
            }
        },
        imageAvatar: {
            get() {
                return this.$store.getters.getImageAvatar
            },
            set(imageAvatar) {
                this.$store.commit('setImageAvatar', imageAvatar)
            }
        },
        locale: {
            get() {
                return this.$store.getters.getLocale
            },
            set(locale) {
                this.$i18n.locale = locale
                this.$store.commit('setLocale', locale)
            }
        },
        showAvatar() {
            return this.imageAvatar ?? this.avatar
        },
        accountId() {
            if (this.account && this.account.$id) {
                return this.account.$id
            } else if (this.session && this.session.userId) {
                return this.session.userId
            }

            return null
        }
    },
    methods: {
        async requestSession() {
            try {
                const session = await this.accountSdk.createEmailSession(this.email, this.password)
                if (process.env.NODE_ENV !== 'production') {
                    console.warn('SESSION', session)
                }
                this.session = session
                return session
            } catch (e) {
                console.error(e)
                this.isLoading = false
                this.__showErrorMessage(e)
                return null
            }

        },
        async login() {
            if (this.$refs.loginForm.validate()) {
                this.isLoading = true
                await this.requestSession()
                if (this.session) {
                    const payload = {
                        account: await this.getAccount(),
                        teams: await this.getTeams(),
                        groups: await this.getGroups(),
                        notifications: await this.getNotifications(),
                        avatar: this.getInitials(),
                        locale: await this.getUserLocale(),
                        imageAvatar: await this.getImageAvatarURL()
                    }

                    console.warn('PAYLOAD', payload)
                    this.$store.dispatch('syncLoginData', payload).then(async () => {
                        await this.$router.replace('/')
                    })
                }
            }
        },

        /**
         * @param data
         * @returns {Promise<void>}
         *
         * @todo Do we need this?
         */
        async createAccount(data) {
            if (typeof data === 'undefined') {
                data = {
                    userId: 'unique()',
                    email: 'pankovap+1@gmail.com',
                    password: '12345678',
                    name: 'Test User'
                }
            }
            const account = new Account(this.$appwrite)
            const response = await account.create(data.userId, data.email, data.password, data.name)
            console.log(response);
        },
        startLogout() {
            this.runConfirm(this.$t('_are_you_sure'), this.$t('_logout_description'), this.logout)
        },
        async logout(answer) {
            if (answer) {
                this.isLoading = true
                try {
                    await this.accountSdk.deleteSession('current')
                } catch (e) {
                    console.error(e)
                } finally {
                    await this.$store.dispatch('cleanLogin')
                    await this.$router.replace('/login')
                }
            }
            this.cancelConfirm()
        },

        async checkIsLoggedIn() {
            try {
                const account = await this.accountSdk.get()
                this.loggedIn = true
                this.account = account
            } catch (e) {
                this.loggedIn = false
                this.account = null
            }
        },

        async updatePrefs(prefs) {
            try {
                this.account = await this.accountSdk.updatePrefs(prefs)
            } catch (e) {
                console.error(e)
            }
        },

        async updateName(name) {
            try {
                this.account = await this.accountSdk.updateName(name);
            } catch (e) {
                console.error(e)
            }
        },

        async updateEmail(email, currentPassword) {
            try {
                this.account = await this.accountSdk.updateEmail(email, currentPassword)
            } catch (e) {
                console.error(e)
            }
        },

        async updatePhone(phone, currentPassword) {
            try {
                this.account = await this.accountSdk.updatePhone(phone, currentPassword)
            } catch (e) {
                console.error(e)
            }
        },

        async updatePassword(password, currentPassword) {
            try {
                this.account = await this.accountSdk.updatePassword(password, currentPassword)
            } catch (e) {
                console.error(e)
            }
        },

        async getNotifications() {
            return new Promise(async (resolve) => {
                const notifications = await this.__get(
                    process.env.VUE_APP_NOTIFICATIONS_COLLECTION_ID,
                    [
                        Query.equal('recipient_id', this.session.userId),
                        Query.equal('is_read', false)
                    ]
                )
                console.warn('Notifications', notifications)
                resolve(notifications)
            })

        },

        async getTeams() {
            return new Promise(async (resolve, reject) => {
                let memberships = {
                    admin: false,
                    teams: []
                }
                try {
                    const response = await this.teamsSdk.list()
                    if (response.total > 0) {
                        for (const team in response.teams) {
                            if (response.teams[team].name === 'Masters') {
                                memberships['admin'] = true
                            }
                            const teamMembership = await this.teamsSdk.listMemberships(response.teams[team].$id, [
                                    // Query.equal('user_id', this.session.userId)
                                ],
                                this.session.userId)
                            memberships.teams.push(teamMembership.memberships[0])
                        }
                    }
                    console.warn("READY TO RETURN", memberships)
                    resolve(memberships)
                } catch (e) {
                    console.error(e)
                    reject()
                }
            })
        },
        async getGroups() {
            return []
        },
        leaveTeam(teamId) {
            console.log(teamId)
        },

        async sendInvite(userData) {
            const invite = await this.__runFunction(
                'user_send_invite',
                userData
            )
            console.warn('Invite sent', invite)
            this.isInvitesUpdate = true
            return invite
        },

        async getAccount() {
            return await this.accountSdk.get()
        },

        async getAllUsers(
            withExtendedProfile = true,
            page = 1,
            last_id = '',
            limit = 25,
            direction = 'after',
            orderType = 'ASC'
        ) {

            // try {
            const data = {
                limit: limit,
                offset: (page - 1) * limit,
                cursor: last_id,
                cursor_direction: direction,
                order_type: orderType
            };

            const users = await this.__runFunction('get_all_users', data)
            if (users.result && users.users.total > 0) {
                console.log('UUU', users.users.users)
                if (withExtendedProfile) {
                    let items = await Promise.all(
                        users.users.users.map(async (v) => {
                                const extendedProfile = await this.getExtendedProfile(v.$id)
                                if (extendedProfile) {
                                    v.avatar_url = extendedProfile.avatar_url
                                    v.avatar_id = extendedProfile.avatar_id
                                } else {
                                    v.avatar_id = undefined
                                    v.avatar_url = await this.getInitials(v.name)
                                }
                                return v
                            }
                        ))

                    console.log('items', items)
                }

                return users.users
            }

            return null
        },

        async getUserLocale() {
            const locale = await this.localeSdk.get()
            console.log('LOcale', locale)
            return locale
        },

        async banUnbanUser(id, status, toggle = true) {
            return await this.__runFunction('banUnbanUser', {
                id: id,
                status: status,
                toggle: toggle
            })
        },

        async searchByEmail(term) {
            console.warn('TERM', term)
            const response = await this.__runFunction('getUserIdByEmail', {
                email: term,
                search: true,
            });

            console.warn('RESP', response)

            if (response.result) {
                return response.users
            }

            console.warn(response)
            return []
        },
        async getExtendedProfile(id = null) {
            if (null === id) {
                id = this.accountId
            }
            if (id) {
                return await this.__getSingleFromQuery(
                    'profiles',
                    [
                        Query.equal('user_id', id)
                    ]
                )
            }

            return null
        },
        async deleteAvatarImage() {
            const extendedProfile = await this.getExtendedProfile()
            if (extendedProfile) {
                const avatarId = extendedProfile.avatar_id
                try {
                    const updateAvatarResponse = await this.__update(
                        'profiles',
                        extendedProfile.$id,
                        {
                            avatar_id: null,
                            avatar_url: null
                        })
                    if (updateAvatarResponse) {
                        await this.deleteUnusedAvatar(avatarId)
                    }

                    this.imageAvatar = null

                    return true
                } catch (e) {
                    console.error(e)
                    return false
                }
            }

        },
        async deleteUnusedAvatar(id, bucket = 'uploads') {
            if (id) {
                try {
                    await this.storageSdk.deleteFile(
                        bucket,
                        id
                    )
                    return true
                } catch (e) {
                    console.error(e)
                    return false
                }
            }
        },
        async updateAvatarImage(image) {
            const profile = await this.getExtendedProfile()
            let response
            if (profile) {
                const oldProfileImage = profile.avatar_id
                response = await this.__update(
                    'profiles',
                    profile.$id,
                    {
                        avatar_id: image.index,
                        avatar_url: image.url.href
                    }
                )
                await this.deleteUnusedAvatar(oldProfileImage)
            } else {
                response = await this.__create(
                    'profiles',
                    {
                        user_id: this.account.$id,
                        avatar_id: image.index,
                        avatar_url: image.url.href
                    }
                )
            }

            if (response && response.avatar_id) {
                this.imageAvatar = await this.previewAvatar(response.avatar_id)
            }

            return response
        },

        async previewAvatar(id, bucket = 'uploads', params = {}) {
            const width = params.width ?? 200
            const height = params.height ?? width
            const radius = width / 2
            const image = await this.storageSdk.getFilePreview(
                bucket,
                id,
                width,
                height,
                'center',
                100,
                0,
                '000000',
                radius,
                1,
                0,
                params.background ?? 'FFFFFF',
                'png'
            )

            console.log('IMAGE', image)
            return image.href ?? null
        },

        async getImageAvatarURL(id = null) {
            const result = await this.getExtendedProfile(id)
            if (result && result.avatar_id) {
                const avatar = await this.previewAvatar(
                    result.avatar_id
                )

                this.imageAvatar = avatar

                return avatar
            }

            return null
        },

        __showErrorMessage(e) {
            this.openMessage(e.message, 'error')
        }
    }
}
