import __commonApiMixin from "@/mixins/modules/__commonApiMixin";
import {Query} from "appwrite";
import {transliterate as tr} from "transliteration";

export default {
    mixins: [__commonApiMixin],
    data: () => ({
        manageFunction: 'manageTeams',
    }),
    methods: {
        async getTeamExtended(id) {
            const extended = await this.__getSingleFromQuery(
                'team_profiles',
                [
                    Query.equal('team_id', id)
                ]
            )
        },
        async listTeams() {
            const response = await this.__runFunction('manageTeams', {
                action: 'list'
            })

            console.log('RESP TEAMS', response)

            if (response.result && response.data.total > 0) {
                await Promise.all(
                    response.data.teams.map(async team => {
                        if (!team.avatar_url) {
                            team.avatar_url = await this.avatarsSdk.getInitials(
                                tr(team.name),
                                200,
                                200,
                                undefined
                            )
                        }
                    })
                )
            }

            return response.data
        },
        async deleteTeam(id) {
            return await this.__runFunction(this.manageFunction, {
                action: 'delete',
                id: id
            })
        },
        async updateOrCreateTeamExtended(team_id, extended = {}) {
            return await this.__updateOrCreateWithSearch(
                'team_profiles',
                extended,
                [
                    Query.equal('team_id', team_id)
                ]
            )
        },
        async createTeam(name, email, avatar_url, description) {
            return await this.__runFunction(this.manageFunction, {
                action: 'create',
                name: name,
                member_email: email,
                description: description
            })
        },
        async getSingleTeam(id) {
            const response = await this.__runFunction(this.manageFunction, {
                action: 'single',
                id: id
            })

            if (response.result) {
                console.log('FAST!!!')
                const extended = await this.getTeamExtended(response.data.$id)
                if (extended) {
                    response.data.avatar_url = extended.avatar_url
                }

                return response.data
            }

            return null
        },
        async updateTeam(id, name, avatar_url, description) {
            const team = await this.__runFunction(this.manageFunction, {
                action: 'update',
                id: id,
                name: name,
                avatar_url: avatar_url,
                description: description
            })

            return team
        },
        async getTeamMembers(id) {
            const response = await this.__runFunction(this.manageFunction, {
                action: 'members',
                id
            })
            return response.result ? response.data.memberships : null
        },

        async createOrUpdateMembership(teamId, email, roles, membershipId, update = false) {
            let response
            if (update) {
                response = await this.__runFunction(this.manageFunction, {
                    action: "updateMembership",
                    id: teamId,
                    membership_id: membershipId,
                    roles: roles
                })
            } else
                response = await this.__runFunction(this.manageFunction, {
                    action: 'invite',
                    id: teamId,
                    member_email: email,
                    roles: roles
                })

            return response.result ?? null
        },

        async deleteMembership(teamId, membershipId) {
            const response = await this.__runFunction(this.manageFunction, {
                action: 'delete_membership',
                id: teamId,
                membership_id: membershipId
            })

            return response && response.result
        },

        async getSingleMembership(teamId, membershipId) {
            const response = await this.__runFunction(this.manageFunction, {
                action: 'single_membership',
                id: teamId,
                membership_id: membershipId
            })

            if (response && response.result) {
                return response.data
            }

            return null
        },
        async editMembership(teamId, membershipId, roles) {
            const response = await this.__runFunction(this.manageFunction, {
                action: 'updateMembership',
                id: teamId,
                membership_id: membershipId,
                roles: roles,
            });

            if (response && response.result) {
                return response.data
            }

            return null
        }
    }
}
