import __commonApiMixin from "@/mixins/modules/__commonApiMixin";
import {transliterate as tr} from "transliteration";

export default {
    mixins: [__commonApiMixin],
    methods: {
        getInitials(name = null) {
            name = name ?? undefined
            const initialsURL = this.avatarsSdk.getInitials(
                tr(name),
                200,
                200,
                undefined
            )
            return initialsURL.href
        },
        getFlag(country) {
            const flag = this.avatarsSdk.getFlag(country, 32, 32)
            return flag.href
        },
    }
}
