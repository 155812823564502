import {Databases, Functions, Avatars, Account, Teams, Locale, Storage} from 'appwrite'

export default {
    computed: {
        databasesSdk() {
            return new Databases(this.$appwrite)
        },
        functionsSdk() {
            return new Functions(this.$appwrite)
        },
        avatarsSdk() {
            return new Avatars(this.$appwrite)
        },
        accountSdk() {
            return new Account(this.$appwrite)
        },
        teamsSdk() {
            return new Teams(this.$appwrite)
        },
        localeSdk() {
            return new Locale(this.$appwrite)
        },
        storageSdk() {
            return new Storage(this.$appwrite)
        },
        mainDatabase() {
            return process.env.VUE_APP_DATABASE_ID
        }
    },
    methods: {
        async __updateOrCreate(table, data, id) {
            const object = this.__getSingle(table, id)
            if (object) {
                return await this.__update(table, id, data)
            }

            return await this.__create(table, data)
        },
        async __updateOrCreateWithSearch(table, data, queries = []) {
            const object = await this.__getSingleFromQuery(
                table,
                queries
            )

            if (object) {
                return await this.__update(
                    table,
                    object.$id,
                    data
                )
            }

            return await this.__create(
                table,
                data
            )
        },
        async __update(table, id, data) {
            try {
                const response = await this.databasesSdk.updateDocument(
                    this.mainDatabase,
                    table,
                    id,
                    data)
                if (process.env.NODE_ENV !== 'production') {
                    console.log(response)
                }
                return response
            } catch (e) {
                console.error(e)
                return false
            }
        },
        async __create(table, data) {
            try {
                const response = await this.databasesSdk.createDocument(
                    this.mainDatabase,
                    table,
                    'unique()',
                    data)
                if (process.env.NODE_ENV !== 'production') {
                    console.log(response)
                }
                return true
            } catch (e) {
                console.error(e)
                return false
            }
        },
        async __get(table, queries) {
            try {
                const response = await this.databasesSdk.listDocuments(
                    this.mainDatabase,
                    table,
                    queries
                )

                if (process.env.NODE_ENV !== 'production') {
                    console.log(response)
                }
                return response
            } catch (e) {
                console.error(e)
                return null
            }
        },
        async __getSingleFromQuery(table, queries) {
            const response = await this.__get(table, queries,)
            if (response && response.total > 0) {
                return response.documents[0]
            }

            return null
        },
        async __getSingle(table, id) {
            try {
                const response = await this.databasesSdk.getDocument(
                    this.mainDatabase,
                    table,
                    id
                )
                if (process.env.NODE_ENV !== 'production') {
                    console.log(response)
                }
                return response
            } catch (e) {
                console.error(e)
                return false
            }
        },
        async __delete(table, id) {
            try {
                const response = await this.databasesSdk.deleteDocument(
                    this.mainDatabase,
                    table,
                    id)
                if (process.env.NODE_ENV !== 'production') {
                    console.log(response)
                }
                return true
            } catch (e) {
                console.error(e)
                return false
            }
        },
        async __runFunction(functionName, data) {
            console.warn(functionName, data)
            try {
                const functionExecution = await this.functionsSdk.createExecution(
                    functionName,
                    JSON.stringify(data),
                    false
                )

                if (process.env.NODE_ENV !== 'production') {
                    console.log(functionExecution)
                }

                const executionResult = await this.functionsSdk.getExecution(functionName, functionExecution.$id)
                switch (executionResult.status) {
                    case 'failed':
                        if (process.env.NODE_ENV !== 'production') {
                            console.error('FAILED', executionResult.status, executionResult.stderr)
                        }
                        return null
                    case 'completed':
                        if (process.env.NODE_ENV !== 'production') {
                            console.log('FAILED', executionResult.status, executionResult.response, executionResult.stdout)
                        }
                        return JSON.parse(executionResult.response)
                    default:
                        if (process.env.NODE_ENV !== 'production') {
                            console.log('FAILED', executionResult.status, executionResult.response, executionResult.stdout, executionResult.stderr)
                        }
                        return null
                }
            } catch (e) {
                console.error(e)
                return null
            }

        },
    }
}
