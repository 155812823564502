import axios from "axios"

const httpServicePlugin = {
    install(Vue) {
        Vue.prototype.$axios = axios

        Vue.prototype.$setupAxios = function () {
            this.$axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
            this.$axios.defaults.headers['Content-Type'] = 'application/json'
        }
    }
}

export default httpServicePlugin
