import __commonApiMixin from "@/mixins/modules/__commonApiMixin";
// import userMixin from "@/mixins/userMixin";

export default {
    mixins: [__commonApiMixin],
    methods: {
        async uploadFilesWithUrls(files, bucket = 'uploads', urlOnly = true, withFileId = true) {
            const uploadedFiles = await this.uploadFiles(files, bucket)
            if (uploadedFiles && uploadedFiles.length > 0) {
                const urls = []
                for (const i in uploadedFiles) {
                    const url = await this.convertIdToUrl(uploadedFiles[i].$id)
                    if (withFileId) {
                        const urlRecord = {
                            id: uploadedFiles[i].$id,
                            url: url
                        }
                        urls.push(JSON.stringify(urlRecord))
                    } else {
                        urls.push(url.href)
                    }
                }
                return urls
            }

            return null
        },
        async uploadFiles(fileObjects, bucket = 'uploads') {
            let returnValue = []

            for (let i = 0; i < fileObjects.length; i++) {
                const uploadedFile = await this.uploadSingleFile(fileObjects[i], bucket)
                if (uploadedFile) {
                    returnValue.push(uploadedFile)
                } else {
                    return null
                }
            }

            return returnValue
        },
        async uploadSingleFile(fileObject, bucket) {
            try {
                const response = await this.storageSdk.createFile(
                    bucket,
                    'unique()',
                    fileObject
                )

                if (process.env.NODE_ENV !== 'production') {
                    console.log(response)
                }

                return response
            } catch (e) {
                console.error(e)
                return null
            }
        },
        async convertIdToUrl(uploadFileId, bucket = "uploads") {
            try {
                const response = await this.storageSdk.getFileView(bucket, uploadFileId)

                if (process.env.NODE_ENV !== 'production') {
                    console.log(response)
                }

                return response
            } catch (e) {
                console.error(e)
                return null
            }
        }
    }
}
