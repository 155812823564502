<template>
  <v-snackbar
    v-model="currentMessage.show"
    :color="color"
    :timeout="currentMessage.timeout"
    multi-line
    absolute
  >
    {{ $t(currentMessage.message) }}
    <template #action="{attrs}">
      <v-btn
        color="white"
        icon
        v-bind="attrs"
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import interfaceMixin from "@/mixins/interfaceMixin";
export default {
  name: "SnackMessage",
  mixins: [interfaceMixin],
  emits: ['close'],
  computed: {
    color() {
      switch (this.currentMessage.type) {
        case 'info':
          return 'primary'
        case 'success':
          return 'success'
        case 'error' :
          return 'red accent-2'
        default:
          return 'deep-purple accent-4'
      }
    }
  },
  watch: {
    show(value) {
      this.open = value
    }
  },
  mounted() {
    this.open = this.show
  },
  methods: {
    close() {
      this.closeMessage()
      this.$emit('close')
    }
  }
}
</script>
