const state = () => ({
    drawer: true,
    updateBreeders: false,
    updateOwners: false,
    updateHandlers: false,
    updateInvites: false,
    confirm: {
        show: false,
        text: '',
        title: '',
        isRunning: false
    },
    updatedTeams: false,
    loadPage: false,
    snackMessage: {
        show: false,
        message: '',
        type: 'info',
        timeout: -1
    },
    header: null,
})

const mutations = {
    toggleDrawer: (state) => {
        state.drawer = !state.drawer
    },
    shouldUpdateBreeders: (state, what) => {
        state.updateBreeders = what
    },
    shouldUpdateOwners: (state, what) => {
        state.updateOwners = what
    },
    shouldUpdateHandlers: (state, what) => {
        state.updateHandlers = what
    },
    runConfirm: (state, confirm) => {
        state.confirm = confirm
    },
    updatedTeams: (state, updated) => {
        state.updatedTeams = updated
    },
    setLoadPage: (state, loading) => {
        state.loadPage = loading
    },
    openMessage: (state, params) => {
        params.show = true
        state.snackMessage = params
    },
    closeMessage: (state) => {
        state.snackMessage = {
            show: false,
            message: '',
            type: 'info',
            timeout: -1
        }
    },
    setHeader: (state, header) => {
        state.header = header
    },
    shouldUpdateInvites: (state, what) => {
        state.updateInvites = what
    }
}

const getters = {
    getDrawer: (state) => {
        return state.drawer
    },
    getBreedersUpdated: (state) => {
        return state.updateBreeders
    },
    getOwnersUpdated: (state) => {
        return state.updateOwners
    },
    getHandlersUpdate: (state) => {
        return state.updateHandlers
    },
    getComfirm: (state) => {
        return state.confirm
    },
    getTeamsUpdated: (state) => {
        return state.updatedTeams
    },
    pageIsLoading: (state) => {
        return state.loadPage
    },
    getMessage: (state) => {
        return state.snackMessage
    },
    getHeader: (state) => {
        return state.header
    },
    getUpdateInvites: (state) => {
        return state.updateInvites
    }
}

export default {
    state,
    mutations,
    getters
}

