<template>
  <v-dialog
      persistent
      max-width="290"
      :value="showing"
  >
    <v-card>
      <v-card-title>{{ $t(title) }}</v-card-title>
      <v-card-text>{{ $t(text) }}</v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            small
            text
            color="primary"
            :disabled="isRunning"
            @click="onAnswer(false)"
        >
          {{ $t('_no') }}
        </v-btn>
        <v-btn
            small
            color="rbutton"
            :loading="isRunning"
            dark
            @click="onAnswer(true)"
        >
          {{ $t('_yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import interfaceMixin from "@/mixins/interfaceMixin";

export default {
  name: 'ConfirmAlertComponent',
  mixins: [interfaceMixin],
  computed: {
    text() {
      return this.showConfirm.text
    },
    title() {
      return this.showConfirm.title
    },
    showing() {
      return this.showConfirm.show
    },
    isRunning() {
      return this.showConfirm.isRunning
    },
    handler() {
      return this.showConfirm.handler
    }
  },
  methods: {
    onAnswer(what) {
      this.handler(what)
    }
  },
}
</script>
