<template>
  <v-overlay
    :opacity="0.7"
  >
    <v-progress-circular
      indeterminate
    />
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingOverlay'
}
</script>
