import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

const AboutComponent = () => import('../views/AboutView')
const ProfileComponent = () => import('../views/Profile')
const NotificationsComponent = () => import('../views/Notifications')
const PageNotFoundComponent = () => import('../views/PageNotFound')
const LoginComponent = () => import('../views/LoginView')
const DashboardComponent = () => import('../views/Dashboard')

const AdminAccountsComponent = () => import('../views/admin/AdminAccountsView')
const AdminEventEditorComponent = () => import('../views/admin/AdminEventEditorView')
const AdminPersonsComponent = () => import('../views/admin/AdminPersonsView')
const AdminTeamsGroupComponent = () => import('../views/admin/AdminTeamsGroupsView')
const AdminExhibitionsComponent = () => import('../views/admin/AdminExhibitionsView')
const AdminAchievementsComponent = () => import('../views/admin/AdminAchievementsView')
const AdminMetricsComponent = () => import('../views/admin/AdminMetricsView')
const AdminOtherListsComponent = () => import('../views/admin/AdminOtherListsView')
const AdminDogsComponent = () => import('../views/admin/AdminDogs')

const MyDogsComponent = () => import('../views/account/MyDogs')
const MyExhibitionsComponent = () => import('../views/account/MyExhibitions')
const SingleTeamComponent = () => import('../views/account/SingleTeam')

const AcceptInviteComponent = () => import('../views/public/AcceptInvite')

const TestComponent = () => import('../views/Test')

const SingleDogComponent = () => import('../views/SingleDog')

Vue.use(VueRouter)

const openRoutes = [
    'acceptInvite'
]

const routes = [
    {
        path: '/',
        name: 'home',
        component: DashboardComponent,
        children: [
            {
                path: '/profile',
                name: 'profile',
                component: ProfileComponent,
                meta: {
                    title: '_profile'
                }
            },
            {
                path: '/notifications',
                name: 'notifications',
                component: NotificationsComponent,
                meta: {
                    title: '_notifications'
                }
            },
            {
                path: '/admin/accounts',
                name: 'adminAccounts',
                component: AdminAccountsComponent,
                meta: {
                    title: '_admin_accounts'
                }
            },
            {
                path: '/admin/event-editor',
                name: 'adminEventEditor',
                component: AdminEventEditorComponent,
                meta: {
                    title: '_admin_event_editor'
                }
            },
            {
                path: '/admin/dogs',
                name: 'adminDogs',
                component: AdminDogsComponent,
                meta: {
                    title: '_admin_dogs'
                }
            },
            {
                path: '/admin/persons',
                name: 'adminPersons',
                component: AdminPersonsComponent,
                meta: {
                    title: '_admin_persons'
                }
            },
            {
                path: '/admin/teams-groups',
                name: 'adminTeamsGroups',
                component: AdminTeamsGroupComponent,
                meta: {
                    title: '_admin_teams_groups'
                }
            },
            {
                path: '/admin/exhibitions',
                name: 'adminExhibitions',
                component: AdminExhibitionsComponent,
                meta: {
                    title: '_admin_shows'
                }
            },
            {
                path: '/admin/achievements',
                name: 'adminAchievements',
                component: AdminAchievementsComponent,
                meta: {
                    title: '_admin_achievements'
                }
            },
            {
                path: '/admin/metrics',
                name: 'adminMetrics',
                component: AdminMetricsComponent,
                meta: {
                    title: '_admin_metrics'
                }
            },
            {
                path: '/admin/other-lists',
                name: 'adminOtherLists',
                component: AdminOtherListsComponent,
                meta: {
                    title: '_admin_other_lists'
                }
            },
            {
                path: '/dogs',
                name: 'dogs',
                component: MyDogsComponent,
                meta: {
                    title: '_my_dogs'
                }
            },
            {
                path: '/exhibitions',
                name: 'exhibitions',
                component: MyExhibitionsComponent,
                meta: {
                    title: '_my_shows'
                }
            },
            {
                path: '/teams/:id',
                name: 'teamsId',
                component: SingleTeamComponent,
                meta: {
                    title: '_teams'
                }
            },
        ]
    },
    {
        path: '/test',
        name: 'test',
        component: TestComponent
    },
    {
        path: '/login',
        name: 'login',
        component: LoginComponent,
        meta: {
            title: '_login'
        }
    },
    {
        path: '/invite/:key',
        name: 'acceptInvite',
        component: AcceptInviteComponent,
        meta: {
            title: '_invite'
        }
    },
    {
        path: '/dogs/view/:id',
        name: 'singleDog',
        component: SingleDogComponent,
        meta: {
            title: '_view_dog'
        }
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: AboutComponent,
        meta: {
            title: '_about'
        }
    },
    {
        path: "*",
        component: PageNotFoundComponent,
        meta: {
            title: '_not_found'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    console.log('LOGGED?', store.getters.getIsLogged)
    store.commit('setHeader', to.meta.title)
    if (openRoutes.includes(to.name) || to.name === 'login') {
        next()
    } else if (store.getters.getIsLogged) {
        next()
    } else {
        next({name: 'login'})
    }
})

router.afterEach(() => {
    store.commit('setLoadPage', false)
    store.commit('closeMessage')
})

export default router
