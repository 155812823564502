import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import httpService from "@/plugins/axios";
import vuetify from './plugins/vuetify'
import "./plugins/tiptap-vuetify"
import appwriteService from "./plugins/appwrite"
import "./plugins/vuetify-mask"
import {VueQueryPlugin} from "vue-query";

import ConfirmAlert from "@/components/alerts/ConfirmAlert"
import LoadingOverlay from "@/components/LoadingOverlay"
import SnackMessage from "@/components/SnackMessage"
import i18n from './i18n'

import {momentConvert, linkArrayConvert, sexImageConvert, momentConvertFromTimestamp, linkCallback} from './functions'

Vue.config.productionTip = false
Vue.use(httpService)
Vue.use(appwriteService)
Vue.use(VueQueryPlugin)

Vue.component('ConfirmAlert', ConfirmAlert)
Vue.component('LoadingOverlay', LoadingOverlay)
Vue.component('SnackMessage', SnackMessage)

const app = new Vue({
    store,
    router,
    vuetify,
    i18n,
    render: h => h(App)
})

app.$setupAxios()
app.$setupAppwrite()

i18n.locale = store.getters.getLocale

window.momentConvert = momentConvert
window.linkArrayConvert = linkArrayConvert
window.sexImageConvert = sexImageConvert
window.momentConvertFromTimestamp = momentConvertFromTimestamp
window.linkCallback = linkCallback


app.$mount('#app')

