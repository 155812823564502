import moment from "moment";
import {uuid} from "vue-uuid";

const momentConvert = (value) => {
    return moment(value).format('LL')
}

const momentConvertFromTimestamp = (value) => {
    return moment(value, "X").format('LL')
}

const linkArrayConvert = (value, path = 'breeders', link = true, callbackData = false) => {
    if (value) {
        const parts = value.split('|')
        if (parts && typeof parts === 'object') {
            if (callbackData) {
                return `<a href="#" data-attribute="${parts[1]}" class="${callbackData}">${parts[0]}</a>`
            } else {
                return link ? `<a href="/${path}/${parts[1]}">${parts[0]}</a>` : parts[0]
            }
        }
        return value
    }
    return undefined
}

const sexImageConvert = (value) => {
    return value === 'male' ?
        '<svg style="width:24px;height:24px" viewBox="0 0 24 24">\n' +
        '    <path fill="currentColor" d="M9,9C10.29,9 11.5,9.41 12.47,10.11L17.58,5H13V3H21V11H19V6.41L13.89,11.5C14.59,12.5 15,13.7 15,15A6,6 0 0,1 9,21A6,6 0 0,1 3,15A6,6 0 0,1 9,9M9,11A4,4 0 0,0 5,15A4,4 0 0,0 9,19A4,4 0 0,0 13,15A4,4 0 0,0 9,11Z" />\n' +
        '</svg>' :
        '<svg style="width:24px;height:24px" viewBox="0 0 24 24">\n' +
        '    <path fill="currentColor" d="M12,4A6,6 0 0,1 18,10C18,12.97 15.84,15.44 13,15.92V18H15V20H13V22H11V20H9V18H11V15.92C8.16,15.44 6,12.97 6,10A6,6 0 0,1 12,4M12,6A4,4 0 0,0 8,10A4,4 0 0,0 12,14A4,4 0 0,0 16,10A4,4 0 0,0 12,6Z" />\n' +
        '</svg>'
}

const getPureId = (value) => {
    if (value) {
        const arr = value.split('|')
        if (arr && typeof arr === 'object') {
            return arr[1]
        }
    }
    return value
}

const cleanupDbObject = (object, exclude = []) => {
    const toDeleteArray = [
        '$id',
        '$collectionId',
        '$createdAt',
        '$permissions',
        '$updatedAt',
        '$databaseId'
    ]
    for (const i in toDeleteArray) {
        if (!exclude.includes(toDeleteArray[i])) {
            delete object[toDeleteArray[i]]
        }
    }

    return object
}

const teamAvatar = async (team) => {
    if (team.profile && team.profile.avatar_url) {
        return team.profile.avatar_url
    } else {
        return team.avatar_url.href
    }
}

const urlToFile = async (url) => {
    console.warn('URL', url)
    const response = await fetch(url)
    const blob = await response.blob()
    const fileName = uuid.v4()
    const fileExt = blob.type.split('/').pop()
    console.log(`${fileName}.${fileExt}`)
    return new File(
        [blob],
        `${fileName}.${fileExt}`,
        {
            type: blob.type
        }
    )
}

const linkCallback = e => {
    console.log(e)
}

export {
    momentConvert,
    momentConvertFromTimestamp,
    linkArrayConvert,
    sexImageConvert,
    getPureId,
    cleanupDbObject,
    urlToFile,
    teamAvatar,
    linkCallback
}
