<template>
  <v-app>
    <loading-overlay v-if="isLoading" />
    <v-main v-else>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import userMixin from "@/mixins/userMixin";
import interfaceMixin from "@/mixins/interfaceMixin";

export default {
  name: 'App',
  mixins: [userMixin, interfaceMixin],
  data: () => ({
    showApp: false,
  }),
  beforeMount() {
    this.checkIsLoggedIn()
    console.log(this.$vuetify.breakpoint.mdAndDown)
  }
};
</script>
