import {Client} from "appwrite";

const client = new Client();

const appwriteServicePlugin = {
    install(Vue) {
        Vue.prototype.$appwrite = client

        Vue.prototype.$setupAppwrite = function () {
            this.$appwrite.setEndpoint(process.env.VUE_APP_API_ENDPOINT)
            this.$appwrite.setProject(process.env.VUE_APP_PROJECT_ID)
        }
    }
}

export default appwriteServicePlugin




